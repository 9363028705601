import React from "react"
import Swiper from "react-id-swiper"

const LazyloadImage = () => {
  const params = {
    lazy: true,
    slidesPerView: 2,
    spaceBetween: 40,
    freeMode: true,
    grabCursor: true,
    mousewheel: {
      invert: true,
      forceToAxis: true, // Set to true to force mousewheel swipes to axis.
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <div>
      <Swiper {...params}>
        <div>
          <img
            alt="img"
            data-src="http://lorempixel.com/1600/1200/nature/1/"
            className="swiper-lazy"
          />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
        </div>
        <div>
          <img
            alt="img"
            data-src="http://lorempixel.com/1600/1200/nature/2/"
            className="swiper-lazy"
          />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
        </div>
        <div>
          <img
            alt="img"
            data-src="http://lorempixel.com/1600/1200/nature/3/"
            className="swiper-lazy"
          />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
        </div>
        <div>
          <img
            alt="img"
            data-src="http://lorempixel.com/1600/1200/nature/4/"
            className="swiper-lazy"
          />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
        </div>
      </Swiper>
    </div>
  )
}

export default LazyloadImage
